<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">
        <b-form-group
          :label="$t('Титул')"
          label-for="title_uz"
        >
          <validation-provider
            #default="{ errors }"
            name="title_uz"
            rules="required"
          >
            <b-form-input
              id="title_uz"
              v-model="form.title_uz"
              :state="errors.length > 3 ? false : null"
              name="title_uz"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Шаблон')"
          label-for="body_uz"
        >
          <validation-provider
            #default="{ errors }"
            name="body_uz"
            rules="required"
          >
            <b-form-textarea
              id="body_uz"
              v-model="form.body_uz"
              rows="6"
              :state="errors.length > 3 ? false : null"
              name="body_uz"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        id: null,
        title_uz: null,
        body_uz: null,
      },
      roles: [],
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t('Добавить')
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$emit('onSuccess')
            this.visible = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.form.id = item.id
      this.form.title_uz = item.title_uz
      this.form.body_uz = item.body_uz
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      storeItem: 'sms/store',
      updateItem: 'sms/update',
    }),
  },
}
</script>

<style scoped></style>
